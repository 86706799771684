<template>
  <v-card class="mx-auto" max-width="500">
    <v-toolbar :color="color" dark>
      <v-toolbar-title>{{ title }}</v-toolbar-title>

      <v-spacer></v-spacer>

      <!-- Badge for number of unknown messages -->
      <v-badge
        v-if="unknownMessagesCount > 0"
        :content="unknownMessagesCount"
        color="orange"
        overlap
      >
        <v-icon>mdi-bell-alert</v-icon>
      </v-badge>
    </v-toolbar>
    <!-- Search Box -->
    <v-text-field
      v-model="search"
      label="Search events"
      append-icon="mdi-magnify"
      single-line
      hide-details
    ></v-text-field>
    <v-list two-line>
      <v-list-item-group v-model="selected" :active-class="color + '--text'">
        <template v-for="item in filteredItems">
          <v-list-item :key="item.id" @click="openModal(item)">
            <template v-slot:default="{}">
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.comment }}
                </v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-list-item-action-text
                  v-text="item.comment_date"
                ></v-list-item-action-text>

                <!-- Icons to show final status -->
                <v-icon v-if="item.statut_id == '0'" color="grey">
                  mdi-help-circle-outline
                </v-icon>
                <v-icon
                  v-if="item.statut_id == '1' && item.journals != null"
                  color="green"
                >
                  mdi-check-circle
                </v-icon>
                <v-icon
                  v-if="item.statut_id == '1' && item.journals == null"
                  color="red"
                >
                  mdi-close-circle
                </v-icon>
              </v-list-item-action>
            </template>
          </v-list-item>
        </template>
      </v-list-item-group>
    </v-list>

    <!-- Modal Dialog for Viewing and Accepting/Discarding the Message -->
    <v-form ref="form" lazy-validation>
      <v-dialog v-model="dialog" max-width="800px" v-if="selectedMessage">
        <v-card>
          <v-card-title>{{ selectedMessage.comment }}</v-card-title>

          <v-card-text>
            <p>Date: {{ selectedMessage.comment_date }}</p>
            <p>
              Status:
              <span v-if="selectedMessage.statut_id == '0'">Unknown</span>
              <span v-if="selectedMessage.statut_id == '1'">Processed</span>
            </p>
          </v-card-text>

          <v-card-actions>
            <!-- Accept and Discard buttons inside modal -->
            <v-select
              :items="journals"
              v-model="selectedjournals"
              item-text="label"
              item-value="id"
              label="Journals"
              outlined
              multiple
              chips
              dense
            >
              <template v-slot:selection="{ item }">
                <!-- HTML that describe how select should render selected items -->
                <v-chip small :color="item.color">
                  {{ item.label }}
                </v-chip>
              </template>
            </v-select>
            <v-spacer></v-spacer>
            <v-btn
              small
              color="green"
              @click="updateStatut(selectedMessage.id, 1)"
            >
              Save
            </v-btn>

            <v-btn small @click="dialog = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>

    <template>
      <confirmdialog ref="confirm" />
    </template>
  </v-card>
</template>

<script>
import UPDATE_EVENT from "../graphql/Well/UPDATE_EVENT.gql";
export default {
  components: {
    confirmdialog: () => import("../components/ConfirmDialog.vue"),
  },
  props: {
    title: String,
    color: String,
    items: Array,
    journals: Array,
  },
  data: () => ({
    selected: [],
    selectedjournals: [],
    dialog: false,
    search: "",
    selectedMessage: null,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    // items: [
    //   {
    //     id: "1",
    //     cat_id: "1",
    //     text: `I'll be in your neighborhood doing errands this weekend. Do you want to hang out?`,
    //     date: "2023-05-01",
    //     statut_id: "0",
    //   },
    //   {
    //     id: "2",
    //     cat_id: "1",
    //     text: `Wish I could come, but I'm out of town this weekend.`,
    //     date: "2024-09-11",
    //     statut_id: "0",
    //   },
    //   {
    //     id: "3",
    //     cat_id: "2",
    //     text: "Do you have Paris recommendations? Have you ever been?",
    //     date: "2019-11-15",
    //     statut_id: "0",
    //   },
    //   {
    //     id: "4",
    //     cat_id: "2",
    //     text: "Have any ideas about what we should get Heidi for her birthday?",
    //     date: "2020-06-27",
    //     statut_id: "0",
    //   },
    //   {
    //     id: "5",
    //     cat_id: "3",
    //     text: "We should eat this: Grate, Squash, Corn, and tomatillo Tacos.",
    //     date: "2022-04-25",
    //     statut_id: "0",
    //   },
    // ],
  }),
  computed: {
    // Compute the number of unknown messages
    unknownMessagesCount() {
      return this.items.filter((item) => item.statut_id === "0").length;
    },
    // Filter items based on search input
    filteredItems() {
      return this.items.filter((item) => {
        return item.comment.toLowerCase().includes(this.search.toLowerCase());
      });
    },
  },
  mounted() {},
  methods: {
    openModal(item) {
      this.selectedMessage = item;
      if (this.selectedMessage.journals)
        this.selectedjournals = this.selectedMessage.journals.split(",");
      this.dialog = true;
    },
    async updateStatut(id, newStatus) {
      if (this.$refs.form.validate()) {
        let ok = true;
        if (this.selectedjournals.length == 0) {
          ok = false;
          if (
            await this.$refs.confirm.open(
              "Confirmation",
              "Discarde this event?",
              { color: "orange darken-3" }
            )
          ) {
            ok = true;
          }
        }
        if (ok) {
          const item = this.items.find((item) => item.id === id);
          if (item) {
            let r = this.$maj(UPDATE_EVENT, {
              wellevent: {
                id: id,
                statut_id: newStatus,
                journals: this.selectedjournals.toString(),
              },
            });
            if (r) {
              item.statut_id = newStatus.toString();
              this.dialog = false;
              this.$emit("well_change");
            } else {
              this.snackbar_text = "Saving error";
              this.snackbar_color = "error";
              this.snackbar = true;
            }
          }
        }
      }
    },
  },
};
</script>
